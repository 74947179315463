<template>
  <div v-loading="loading" class="layout mt-4">
    <div
      class="border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
    >
      <h3 class="text-lg font-medium leading-6 text-gray-900">Sent Invoices</h3>
      <div class="flex flex-row space-x-4">
        <el-date-picker
          v-model="dueDate"
          type="date"
          placeholder="Filter by Due Date"
          :size="size"
        />
        <Search
          v-model:search="pagination.search"
          placeholder="Find customer"
        />
      </div>
    </div>

    <div class="flex flex-col" v-if="invoices.length > 0">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200 border">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Customer
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Number
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Due
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Created
                  </th>
                  <th scope="col" class="px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody
                class="divide-y divide-gray-200 bg-white"
                v-if="invoices.length > 0"
              >
                <tr v-for="invoice in invoices" :key="invoice.Id">
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-900">
                      ${{ invoice.AmountDue }} USD
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-900">
                      <span
                        class="inline-flex items-center rounded-md px-2.5 py-0.5 text-xs font-medium"
                        :class="{
                          'bg-green-100 text-green-800': invoice.Status === 2,
                          'bg-red-100 text-red-800': invoice.Status === 3,
                          'bg-yellow-100 text-yellow-800': invoice.Status === 1,
                          'bg-gray-100 text-gray-800': invoice.Status === 0,
                        }"
                      >
                        {{ Status(invoice.Status) }}
                      </span>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm font-medium text-gray-900">
                      {{ invoice.Customer.Name }}
                    </div>
                    <div class="max-w-sm truncate text-sm text-gray-500">
                      {{ invoice.Customer.Email }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <span
                      class="inline-flex items-center rounded-md bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800"
                    >
                      {{ invoice.StripeInvoiceNumber }}
                    </span>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-400">
                      {{
                        new Date(invoice.DueDate).toLocaleDateString("en-us", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })
                      }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-400">
                      {{
                        new Date(invoice.Created).toLocaleDateString("en-us", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })
                      }}
                    </div>
                  </td>
                  <td
                    class="flex-end flex space-x-3 whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                  >
                    <button
                      v-if="invoice.Status !== 2"
                      v-tippy="{
                        content: 'Edit Invoice',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent border-gray-300 px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800"
                      @click="
                        selectedInvoice = invoice;
                        showEditInvoice = true;
                      "
                    >
                      <PencilIcon class="h-5 w-5 flex-shrink-0 text-gray-400" />
                    </button>
                    <a
                      :href="invoice.StripeInvoicePDFUrl"
                      v-tippy="{
                        content: 'Download Invoice',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent border-gray-300 px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800"
                    >
                      <DownloadIcon
                        class="h-5 w-5 flex-shrink-0 text-gray-400"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination :pagination="pagination" @changePage="onChangePage" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <el-empty
        description="Oops, no invoices found."
        :image-size="200"
      ></el-empty>
    </div>
  </div>
  <EditInvoice
    v-if="showEditInvoice"
    @onClose="showEditInvoice = false"
    @onSubmit="onSubmittedInvoice"
    :invoice="selectedInvoice"
  />
</template>

<script>
import { onMounted, reactive, ref, watch } from "vue";

import useInvoicesRepository from "@/composable/useInvoicesRepository";
import Pagination from "@/common/components/pagination";
import Search from "@/common/components/search";
import EditInvoice from "./components/_editInvoice.vue";

import { DownloadIcon, PencilIcon } from "@heroicons/vue/solid";

import { directive } from "vue-tippy";
import "tippy.js/animations/scale.css";

export default {
  components: {
    Pagination,
    DownloadIcon,
    PencilIcon,
    EditInvoice,
    Search,
  },
  directives: {
    tippy: directive,
  },
  setup() {
    const dueDate = ref(null);
    const showEditInvoice = ref(false);
    const { invoices, loading, totalResults, getSentInvoices } =
      useInvoicesRepository();

    const pagination = reactive({
      page: 1,
      pageSize: 10,
      totalResults: totalResults,
      search: "",
    });

    onMounted(() => {
      getSentInvoices(pagination.page, pagination.pageSize, "", dueDate.value);
    });

    const onChangePage = (page) => {
      pagination.page = page;
      getSentInvoices(
        pagination.page,
        pagination.pageSize,
        pagination.search,
        dueDate.value
      );
    };

    const selectedInvoice = ref(null);

    const Status = (status) => {
      switch (status) {
        case 0:
          return "Draft";
        case 1:
          return "Sent";
        case 2:
          return "Paid";
        case 3:
          return "Overdue";
        default:
          return "Unknown";
      }
    };

    const onSubmittedInvoice = () => {
      getSentInvoices(pagination.page, pagination.pageSize, "", dueDate.value);
    };

    watch(
      () => pagination.search,
      (search) => {
        getSentInvoices(1, 10, search, dueDate.value);
      },
      { deep: true }
    );

    watch(
      () => dueDate.value,
      (date) => {
        getSentInvoices(1, 10, pagination.search, date);
      },
      { deep: true }
    );

    return {
      loading,
      invoices,
      pagination,
      onChangePage,
      Status,
      selectedInvoice,
      showEditInvoice,
      dueDate,
      onSubmittedInvoice,
    };
  },
};
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  position: relative;
}
</style>
