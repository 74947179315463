<template>
  <TransitionRoot appear as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 flex max-w-full pl-10">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl"
              >
                <div class="bb px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="pl-6 text-lg font-medium text-gray-900">
                      Edit Invoice
                    </DialogTitle>
                    <div class="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        class="focus:outline-none rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        @click="onClose"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="relative mt-6 flex-1 px-4 sm:px-6"
                  v-loading="loading"
                >
                  <!-- Replace with your content -->
                  <el-form
                    ref="formRef"
                    :model="formModel"
                    :rules="formRules"
                    label-width="auto"
                    label-position="top"
                    status-icon
                  >
                    <el-form-item label="Status" prop="status">
                      <el-select
                        v-model="formModel.status"
                        placeholder="Select a status"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in statusOptions"
                          :key="item"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>

                    <div class="flex flex-row space-x-4">
                      <button
                        type="button"
                        class="focus:outline-none w-1/2 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500"
                        @click="onClose"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        @click="submitForm(formRef)"
                        class="focus:outline-none ml-4 inline-flex w-1/2 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-indigo-500"
                      >
                        Save
                      </button>
                    </div>
                  </el-form>

                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, reactive, toRefs } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import useInvoicesRepository from "@/composable/useInvoicesRepository";

export default {
  name: "EditInvoice",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  emits: ["invoiceEdited", "onClose", "onSubmit"],
  setup(props, { emit }) {
    const formRef = ref(null);
    const { invoice } = toRefs(props);
    const { updateInvoice, loading, voidInvoice, uncollectibleInvoice } =
      useInvoicesRepository();
    const open = ref(true);

    const formModel = reactive({
      id: invoice.value.Id,
      amountDue: invoice.value.AmountDue,
      status: "",
    });

    const onSubmit = async () => {
      if (!formModel.status) {
        return;
      }

      if (formModel.status === "Void") {
        await voidInvoice({ ...invoice.value });
        emit("invoiceEdited", true);
        emit("onClose", true);
        return;
      }

      if (formModel.status === "Uncollectible") {
        await uncollectibleInvoice({ ...invoice.value });
        emit("invoiceEdited", true);
        emit("onClose", true);
        return;
      }

      const data = {
        ...invoice.value,
        AmountDue: formModel.amountDue.value,
        Status: formModel.status.value,
      };
      await updateInvoice(data);
      emit("invoiceEdited", true);
      emit("onClose", true);
    };

    const onClose = () => {
      emit("onClose", true);
    };

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate(async (valid) => {
        if (valid) {
          await onSubmit();
          emit("onClose", true);
          emit("onSubmit", true);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    };

    const statusOptions = ["Paid", "Void"];

    const formRules = reactive({
      status: [
        {
          required: true,
          message: "Please select a status",
          trigger: "change",
        },
      ],
    });

    return {
      open,
      loading,
      onClose,
      onSubmit,
      formRef,
      formModel,
      formRules,
      statusOptions,
      submitForm,
    };
  },
};
</script>

<style scoped>
.el-form-item.el-form-item--feedback {
  display: flex;
  flex-direction: column;
}
</style>
