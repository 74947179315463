import { ref } from "vue";

import API from "@/api";
import { ElNotification } from "element-plus";
import ErrorHandler from "@/services/errorHandler";

export default function useInvoicesRepository() {
  const invoices = ref([]);
  const totalResults = ref(0);
  const loading = ref(false);

  const getInvoices = async (page, limit) => {
    loading.value = true;
    try {
      let result = await API.Organization.Invoices.get(page, limit);
      invoices.value = result.data.List;
      loading.value = false;
    } catch (error) {
      loading.value = false;

      ElNotification({
        title: "Oops",
        message: "Problem while fetching Invoices",
        type: "warning",
      });
    }
  };

  const getDraftInvoices = async (page, limit, search) => {
    loading.value = true;

    try {
      let result = await API.Organization.Invoices.getDraft(
        page,
        limit,
        search
      );
      invoices.value = result.data.List;
      totalResults.value = result.data.TotalResults;
      loading.value = false;
    } catch (error) {
      ElNotification({
        title: "Oops",
        message: "Problem while fetching Invoices",
        type: "warning",
      });
    }
  };

  const getOverdueInvoices = async (page, limit) => {
    loading.value = true;

    try {
      let result = await API.Organization.Invoices.getOverdue(page, limit);
      invoices.value = result.data.List;
      totalResults.value = result.data.TotalResults;
      loading.value = false;
    } catch (error) {
      ElNotification({
        title: "Oops",
        message: "Problem while fetching Invoices",
        type: "warning",
      });
    }
  };

  const getPaidInvoices = async (page, limit) => {
    loading.value = true;
    try {
      let result = await API.Organization.Invoices.getPaid(page, limit);
      invoices.value = result.data.List;
      totalResults.value = result.data.TotalResults;
      loading.value = false;
    } catch (error) {
      ElNotification({
        title: "Oops",
        message: "Problem while fetching Invoices",
        type: "warning",
      });
    }
  };

  const getSentInvoices = async (page, limit, search, dueDate) => {
    loading.value = true;
    try {
      let result = await API.Organization.Invoices.getSent(
        page,
        limit,
        search,
        dueDate
      );
      invoices.value = result.data?.List || [];
      totalResults.value = result.data?.TotalResults || [];
      loading.value = false;
    } catch (error) {
      ElNotification({
        title: "Warning",
        message: new ErrorHandler(error).getMessage(),
        type: "warning",
      });
    }
  };

  const getUnpaidInvoices = async (page, limit) => {
    loading.value = true;
    try {
      let result = await API.Organization.Invoices.getUnpaid(page, limit);
      invoices.value = result.data?.List || [];
      totalResults.value = result.data?.TotalResults || [];
      loading.value = false;
    } catch (error) {
      ElNotification({
        title: "Oops",
        message: "Problem while fetching Invoices",
        type: "warning",
      });
    }
  };

  const saveAsDraft = async (data) => {
    loading.value = true;
    try {
      const response = await API.Organization.Invoices.saveAsDraft(data);
      loading.value = false;

      if (response.status === 200) {
        ElNotification({
          title: "Success",
          message: "Invoice saved as Draft.",
          type: "success",
        });
      } else {
        let firstSentence =
          response?.response?.data?.Message.split(
            "at Stripe.StripeClient"
          )[0] || "Error";

        if (firstSentence) {
          firstSentence = firstSentence?.replace(
            "Stripe.StripeException: ",
            ""
          );
        }

        ElNotification({
          title: "Oops",
          message: "Problem while saving Invoice: " + firstSentence,
          type: "warning",
        });
      }

      // ElNotification({
      //   title: "Success",
      //   message: "Invoice saved as Draft.",
      //   type: "success",
      // });
    } catch (error) {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while saving Invoice",
        type: "warning",
      });
    }
  };

  const send = async (data) => {
    loading.value = true;
    try {
      await API.Organization.Invoices.send(data);
      loading.value = false;
      ElNotification({
        title: "Success",
        message: "Invoice Sent.",
        type: "success",
      });
      getDraftInvoices(1, 10, "");
    } catch (error) {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while sending Invoice",
        type: "warning",
      });
    }
  };

  const updateInvoice = async (data) => {
    loading.value = true;
    try {
      await API.Organization.Invoices.update(data);
      loading.value = false;
      ElNotification({
        title: "Success",
        message: "Invoice Updated.",
        type: "success",
      });
      getDraftInvoices(1, 10, "");
    } catch (error) {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while updating Invoice",
        type: "warning",
      });
    }
  };

  const voidInvoice = async (data) => {
    loading.value = true;
    try {
      await API.Organization.Invoices.void(data);
      loading.value = false;
      ElNotification({
        title: "Success",
        message: "Invoice Voided.",
        type: "success",
      });
      getDraftInvoices(1, 10, "");
    } catch (error) {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while voiding Invoice",
        type: "warning",
      });
    }
  };

  const uncollectibleInvoice = async (data) => {
    loading.value = true;
    try {
      await API.Organization.Invoices.uncollectible(data);
      loading.value = false;
      ElNotification({
        title: "Success",
        message: "Invoice marked as Uncollectible.",
        type: "success",
      });
      getDraftInvoices(1, 10, "");
    } catch (error) {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while marking Invoice as Uncollectible",
        type: "warning",
      });
    }
  };

  return {
    send,
    loading,
    invoices,
    totalResults,
    saveAsDraft,
    getInvoices,
    getSentInvoices,
    getDraftInvoices,
    getPaidInvoices,
    getOverdueInvoices,
    getUnpaidInvoices,
    updateInvoice,
    voidInvoice,
    uncollectibleInvoice,
  };
}
